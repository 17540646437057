@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito&family=Raleway:wght@700&display=swap');

html{
  height: 100%;
  margin:0;
}
body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  background: linear-gradient(to right, #00000011 1px, transparent 1px),linear-gradient(to bottom, #00000011 1px, transparent 1px);
  background-color:#fff9f0;
  background-size:1.5em 1.5em;
  background-repeat: repeat-x repeat-y;
  transition: 200ms cubic-bezier(0.175, 0.885, 0.87, 1.15);
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  font-size: 36px;
}

p{
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}