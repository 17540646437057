.App{
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}


.ContainerGrid{
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  width: 100vw;
  padding: 4em;
  margin: 0;
  box-sizing: border-box;
}

.ContainerCard{
  position: relative;
  margin: auto;
  background-color: white;
  border-radius: 1em;
  height: 80%;
  width: 80%;
  border: 0.1rem solid black;
  padding: 0 4rem;
}

form{
  text-align: left;
}

.button {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-decoration: none;
  border:none;
  padding: 6px 16px;
  background-color: #380601;
  cursor: pointer;
  line-height: 1.2;
  border-radius: 6px;
  transition: 200ms cubic-bezier(0.175, 0.885, 0.87, 1.15);
}

.button:hover{
  background-color: #481A15;
}

.refreshContainer{
  position: relative;
}
.refreshIcon{
  position: absolute;
  top: 2.5em;
  left: 0em;
  cursor: pointer;
}

.Form{

}

.form-row{
  margin-bottom: 0.5em;
}

.form-row label{
  
}

.form-row input{
  width: 100%;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding: 0.25em 0.5em;
  border: solid #E2E8F0 2px;
  border-radius: 8px;
}

.form-row input:focus-visible{
  outline-width: 1px;
  outline-color: rgb(49, 130, 206);
  box-shadow: rgb(49, 130, 206) 0px 0px 0px 1px;
}

.form-row textarea{
  width: 100%;
  max-width: 100%;
  resize: none;
  height: 10em;
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding: 0.25em 0.5em;
  border: solid #E2E8F0 2px;
  border-radius: 8px;
}

.form-row textarea:focus-visible{
  outline-width: 1px;
  outline-color: rgb(49, 130, 206);
  box-shadow: rgb(49, 130, 206) 0px 0px 0px 1px;
}

.Footer{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ContainerGrid{
    padding: 0.5em;
  }
  .ContainerCard{
    padding: 0 1em;
    width: 90%;
  }
  .form-row input{
    width: 97%;
  }
  .form-row textarea{
    width: 97%;
  }
  .Footer{
    padding: 0 1em;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 767px) {
  .ContainerGrid {
    padding: 0.5em;
  }

  .ContainerCard {
    padding: 0 1em;
    width: 90%;
    height: 100%;
    margin: 0 auto;
  }

  .form-row input {
    width: 93%;
  }

  .form-row textarea {
    width: 93%;
  }

}